<template>
  <div class="header-container">
    <h1>{{content.page.title}}</h1>
  </div>
  <div class="container" v-html="content.page.mainContent">

  </div>
</template>

<script>

export default{
  name:'About',
  props:['initData','contents','pageId'],
  data(){

    return{}
  },
  computed:{
    language(){
      return this.initData.init.language
    },
    content(){
      let contents = this.contents[this.pageId]

      this.initData.init.head.pageId = this.pageId
      this.initData.init.head.title = contents.head.title
      this.initData.init.head.keywords = contents.head.keywords
      this.initData.init.head.description = contents.head.description

      return contents
    }
  }
}
</script>
<style scoped>
div {
  padding: 0;
}

.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9rem;
  background-image: linear-gradient(to right, rgba(119, 132, 16, .8), rgba(119, 132, 16, .8)), url("@/assets/img/background.png");
  mix-blend-mode: normal;
}

.header-container h1 {
  color: var(--white);
  font-size: 3rem;
  font-weight: 300;
}

.container:deep(p) {
  margin: 2.5rem 0;
  font-weight: 300;
  line-height: 1.5rem;
}

.container {
  max-width: 1064px;
  margin-bottom: 7rem;
}

.container:deep(.title-row) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5rem;
}

.container:deep(.title-row h2) {
  font-style: normal;
  font-weight: 300;
  font-size: 2.5rem;
  line-height: 3rem;
}

.container:deep(.icons) {
  margin: 0;
  padding: 0;
}

.container:deep(.icons .icon) {
  margin-left: 2rem;
  height: 1.5rem;
  width: 1.5rem;
  filter: brightness(0) saturate(100%) invert(90%) sepia(29%) saturate(3159%) hue-rotate(13deg) brightness(92%) contrast(81%);
}

.container:deep(.legend) {
  background-color: var(--gray);
  width: 100%;
  padding: 1.5rem;
}

.container:deep(.legend p) {
  margin: 0;
  padding: 0;
}

.container:deep(.responsive-youtube){
  overflow:hidden; padding-bottom:56.25%; position:relative; height:0;
}
.container:deep(.responsive-youtube iframe){
  left:0; top:0; height:100%; width:100%; position:absolute;
}

.container:deep(.accent) {
  color: var(--dark-green);
  font-weight: 400;
}

.container:deep(.highlight) {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
}

.container:deep(.highlight p) {
  font-style: italic;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: var(--dark-green);
  padding-left: 2.5rem;
}

.container:deep(.highlight .line) {
  height: 12.5rem;
  width: 1rem;
  background-color: var(--dark-green);
  display: block;
}

@media (max-width: 768px){
  .container {
    padding: 0 1rem;
  }
}

</style>